import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-svg-icon',
  template: `<div [innerHTML]="svgContent" [ngStyle]="{ fill: fill }"></div>`,
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit, OnDestroy {
  @Input() path!: string;
  @Input() fill: string = 'currentColor'; // Default to current text color

  svgContent: SafeHtml | null = null;
  private svgSubscription: Subscription | null = null;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.loadSvg();
  }

  ngOnDestroy() {
    if (this.svgSubscription) {
      this.svgSubscription.unsubscribe();
    }
  }

  private loadSvg() {
    if (this.path) {
      this.svgSubscription = this.http
        .get(this.path, { responseType: 'text' })
        .subscribe(svg => {
          this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svg);
        });
    }
  }
}
